.container {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.content {
  flex-direction: column;
  width: 650px;
  display: flex;
  background-color: #f9f9f9;
  padding: 3rem;
  border-radius: 32px;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  height: 200px;
  text-align: center;
  border-radius: 4px;
  width: 22%;
}

.userName {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
}

.roleText {
  font-family: Montserrat-Extrabold, sans-serif !important;
  font-size: 36px !important;
  font-weight: 100 !important;
  line-height: 44px !important;
  letter-spacing: -0.004em !important;
  text-align: center !important;
}

.form {
  width: 100%;
  display: flex;
  padding: 2rem 1rem 1rem 1rem;
  justify-content: center;
  min-height: 600px;
}

.headerContainer {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: #474747 !important;
}

.header {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 2rem;
  width: 100%;
  text-align: center;
}

.title {
  font-family: Montserrat-Regular, sans-serif;
  margin-right: 40px;
  font-size: 1rem;
  color: #474747;
  margin-bottom: 0;
}

.text {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 1.5rem;
  color: #474747;
  margin-bottom: 0;
}
