.containter {
  width: 100%;
  height: 100%;
  animation-duration: 0.25s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeIn;
  background-color: #f9f9f9;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation-duration: 0.45s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  background: #f9f9f9;
  padding: 20px 120px;
}

.parraph {
  margin: 10px 50px 50px 50px !important;
  white-space: pre-line;
  font-family: 'Montserrat-Regular', sans-serif !important;
  text-align: justify;
}

.titleParraf {
  margin-left: 50px;
  font-weight: bold;
  font-family: 'Montserrat-Regular', sans-serif !important;
}

.titleHeader {
  margin-left: 50px;
  font-family: 'Montserrat-Regular', sans-serif !important;
  font-weight: bold !important;
  color: #2e7161 !important;
  margin-bottom: 20px;
}

.containterLogin {
  width: 100%;
  height: 100%;
}

.contentLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.parraphLogin {
  margin: 10px 50px 50px 50px !important;
  white-space: pre-line;
  font-family: 'Montserrat-Regular', sans-serif !important;
  text-align: justify;
}

.titleParrafLogin {
  margin-left: 50px;
  font-weight: bold;
  font-family: 'Montserrat-Regular', sans-serif !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  30% {
    opacity: 0.3;
    transform: translate3d(0, 70%, 0);
  }

  50% {
    opacity: 0.5;
    transform: translate3d(0, 50%, 0);
  }

  70% {
    opacity: 0.7;
    transform: translate3d(0, 30%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
