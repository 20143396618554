.container {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.content {
  flex-direction: column;
  width: 650px;
  display: flex;
  background-color: #f9f9f9;
  padding: 3rem;
  border-radius: 32px;
}

.title {
  font-family: Montserrat-Regular, sans-serif;
  margin-right: 40px;
  font-size: 1rem;
  color: #474747;
  margin-bottom: 0;
}

.schedule {
  display: flex;
  justify-content: space-between;
}

.header {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 2rem;
  width: 100%;
}

.textTrainingList {
  font-family: Montserrat-Semibold, sans-serif !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
  padding-top: 3%;
}
