.mainContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  padding: 0 0 8px;
  justify-content: center;
}

.logo {
  margin-right: 125px;
  margin-bottom: 28px;
  margin-top: 30px;
  width: 200px;
}

@media (max-width: 1500px) {
  .mainContainer {
    padding: 0 0 8px;
  }
}

@media (max-width: 1200px) {
  .mainContainer {
    padding: 0 0 8px;
  }
}

/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */
/* IT'S SET PADDING IN MAIN CONTAINER CHILD */
