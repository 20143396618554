.container {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.content {
  flex-direction: column;
  width: 650px;
  display: flex;
  background-color: #f9f9f9;
  padding: 3rem;
  border-radius: 32px;
}

.buttons {
  margin-top: 6.5%;
  align-items: flex-end;
  width: 40rem;
  height: 2rem;
}
