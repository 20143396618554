.calendarContainer {
  column-gap: 2rem;
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 0px 45px 0px 45px;
}

.containerSelect {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 90%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.containerFilter {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 90%;
}
.legendCheck2 {
  display: flex;
  font-size: 16px;
  font-weight: bold;
}
.checkboxInput {
  display: none; /* Oculta el input checkbox por defecto */
}
.checkboxImage {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
}

/* Estilos cuando el checkbox está marcado */
.checkboxInput:checked + .checkboxImage {
  opacity: 1; /* Hacer la imagen completamente visible cuando está marcado */
}

/* Ocultar la imagen cuando el checkbox no está marcado */
.checkboxInput:not(:checked) + .checkboxImage {
  display: none;
}
.legendCheck {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px;
}

.chip {
  background-color: #ffffff;
  border: 1px solid #2e7161;
  border-radius: 16px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  padding: 4px;
  margin: 3px 12px 3px 5px;
  white-space: nowrap;
}
.label {
  margin-right: 2% !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left;
  border-bottom: 4px solid #adb84e;
  color: #474747 !important;
  margin-top: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  width: 100% !important;
  border-radius: 27px !important;
  background: #f9f9f9;
  box-shadow: 0 3px 3px 0 #00000040;
  border: none !important;
  margin-bottom: 4px;
}

.headerContainer {
  text-align: left;
  width: 90%;
  margin: 10px;
}

@media (max-width: 349px) {
  .calendarContainerLeft {
    width: 280px;
  }
}

@media (min-width: 350px) {
  .calendarContainerLeft {
    row-gap: 1rem;
    height: fit-content;
    max-width: 320px;
  }
}
.buttonGenerate {
  margin-left: 10px;
}

.datePicker {
  background-color: #8e9631 !important;
}

.MuiPickersToolbar .toolbar {
  background-color: #8e9631 !important;
}

.calendarContainerLeft {
  display: block !important;
  text-align: center !important;
  padding: 0;
  border-radius: 3px;
  background-color: #f9f9f9 !important;
  margin-top: 15px;
}

.headerToolbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h2 {
    color: #adb84e;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.004em;
    text-align: left;
  }

  > h4 {
    color: #474747;
    font-family: Montserrat, sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
  }
}

.newEventButton {
  background-color: #adb84e !important;
  color: #ffffff !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
  height: 74px !important;
  border-radius: 32px !important;
  border: 2px solid #adb84e !important;
  display: flex;
  width: 200px !important;
  flex-direction: column;
}

.newEventButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newEventText {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.004em;
  text-align: left;
  color: #f9f9f9;
  margin-bottom: 4px;
}

.newEventTextExercises {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #f9f9f9;
  margin-bottom: 4px;
}

.calendarContainerLeft > button {
  position: relative !important;
  margin: 4px;
  height: 100px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.calendar {
  border-radius: 6px 6px 3px 3px;
  border: 1px dotted #adb84e;
  overflow: hidden;
  width: auto;
  background-color: #f9f9f9 !important;
}

.calendar header {
  box-shadow: none;
}

.calendar div[role='tablist'] {
  background-color: #f9f9f9;
  color: #282828;
  box-shadow: 0 0 #000;
  border-bottom: 0 #000 solid;
}

.calendar div[role='tablist'] button[aria-selected='true'] {
  background-color: #f9f9f9;
  color: #282828;
  box-shadow: 0 0 #000;
  border-bottom: 0 #000 solid;
}

.calendar .MuiTabs-indicator {
  display: none !important;
}

.parsetabs {
  background-color: #ebedeb !important;
  color: #515151 !important;
}

.parsetabs span {
  font-weight: bold;
  text-align: left;
}

.parsetabsActive {
  background-color: #f9f9f9 !important;
  color: #515151 !important;
}

.parsetabsActive span {
  font-weight: bold;
  text-align: left;
}
.caltabs {
  width: 100%;
}
.caltabs > div > span {
  top: 0;
  height: 4.5px;
  background-color: #474747;
}

.calendar > div > div {
  margin: 10px;
}

@media (max-width: 900px) {
  .calendarContainer {
    display: flex;
    flex-direction: column;
  }
  .calendarContainerLeft {
    row-gap: 1rem;
    height: fit-content;
    max-width: 320px;
    margin: 0 auto 20px auto;
  }
  .caltabs {
    visibility: hidden;
  }
}

.font1 {
  width: 40px !important;
}
