.container {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.content {
  flex-direction: column;
  width: 650px;
  display: flex;
  background-color: #f9f9f9;
  padding: 3rem;
  border-radius: 32px;
}

.title {
  font-family: Montserrat-Regular, sans-serif;
  margin-right: 40px;
  font-size: 1rem;
  color: #474747;
  margin-bottom: 0;
}
