.header {
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--color-light-blue);
}

.label {
  margin-right: 2% !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left;
  color: #2e7161 !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  width: 100% !important;
  border-radius: 27px !important;
  background: #f9f9f9;
  box-shadow: 0 3px 3px 0 #00000040;
  border: none !important;
  margin-bottom: 4px;
  max-height: 400px;
  min-height: 400px;
}

.pageContainer {
  padding-top: 50px;
  width: 100%;
}

.textField {
}

.form {
  width: 100%;
  overflow: auto;
  padding: 28px 48px;
}

.modalGenerateExercise {
  background: white;
  background-color: white;
  color: white;
}
.datePickerContainer {
  width: 70%;
}

.datePicker {
}

.datePicker2 {
  padding-left: 40px;
}

.dateLabel {
  font-weight: bold;
  margin: auto 0;
}

.recurrenceLabel {
  display: inline;
  margin: auto 0;
  padding-right: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.004em;
  text-align: left;
  color: #adb84e;
}

.usersLabel {
  width: 200px;
}

.autocomplete {
  width: 200px;
}

.chip {
  margin: 3px 5px;
}

.filesLabel {
  font-weight: bold;
}

.notificationLabel {
  font-weight: bold;
  font-size: 1rem;
  color: var(--verde-topbar-y-botones);
}

.notificationContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 5px 0;
}

@media (max-width: 1300px) {
  .textField {
    flex-direction: column;
  }
  .datePickerContainer {
    flex-direction: column;
    width: auto;
  }
  .datePicker {
    width: 90%;
  }
  .datePicker2 {
    width: 90%;
    padding-left: 0;
  }
  .autocomplete {
    width: auto;
  }
}
