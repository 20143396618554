.navBarContainer {
  height: 75px;
  padding: 21px 29px 14px 30px;
  background-color: var(--verde-topbar-y-botones);
}
.container {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
  display: flex;
  justify-content: space-between;
}

.navRightContainer {
  display: flex !important;
  align-items: center !important;
  min-width: 1px !important;
}

.notiHead {
  width: 350px;
  height: 75px;
  padding: 15px 142px 16px 20px;
  background-color: var(--gris-fondo-menu-y-textos);
}

.notiHead h6 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  font-family: Montserrat-Regular, sans-serif;
  color: #ffffff;
}

.notiHead a {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--verde-topbar-y-botones);
  font-family: Montserrat-Regular, sans-serif;
  margin: 4px 17px 0 0;
}

.clearAll {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--verde-topbar-y-botones);
  font-family: Montserrat-Regular, sans-serif;
  margin: 4px 17px 0 0;
}

.chatHeader {
  height: 92px;
  padding: 19px 19px 20px 20px;
  background-color: var(--gris-fondo-menu-y-textos);
}

.chatInput {
  height: 60px;
  padding: 17px 325px 16px 13px;
  border-radius: 5px;
  border: solid 1px #d0d6dc;
  background-color: #f5f7fa;
}

.chatNew {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 35px;
  right: 20px;
  border-radius: 100%;
  color: #fff;
  padding: 2px 0;
  text-align: center;
  background: var(--verde-topbar-y-botones);
}

.profileNotification.show .dropdown-menu {
  display: flex;
  flex-direction: column;
}

.profileNotification.show {
  animation: fadeOut 0.5s forwards;
}

.profileNotification {
  animation: fadeIn 0.5s forwards;
  width: 150px !important;
  margin-right: 40px !important;
  margin-bottom: 20px !important;
  background-color: #f9f9f9 !important;
  transform: translate3d(-41.5px, 62px, 0px) !important;
  border-radius: 13px !important;
  min-width: 0 !important;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.profileNotification > li {
  list-style-type: none !important;
}

.profileNotification > ul {
  list-style-type: none !important;
  padding: 0 !important;
}

.profileNotification > li > a {
  text-decoration: none !important;
  color: var(--gris-fondo-menu-y-textos) !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0 !important;
  font-family: Montserrat-Regular, sans-serif !important;
}

.profileNotification::after {
  display: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.textAvatarProfile {
  font-family: Montserrat-SemiBold, sans-serif !important;
}

.userProfile {
  width: 150px;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.logoCareme {
  margin: 37px 50px 10px 27px;
}
.iconCollapse {
  width: 13px;
  margin: 0 5px 17px 7px;
}

.textActive span {
  font-size: 18px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 24px;
  color: var(--verde-topbar-y-botones);
}

.textActiveResponsive span {
  font-size: 12px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--verde-topbar-y-botones);
}

.icon-hover:hover {
  filter: brightness(50%);
}

.textMenu span {
  font-size: 18px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 24px;
  color: var(--color-white);
}

.textMenuResponsive span {
  font-size: 12px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--color-white);
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100% !important;
  margin-top: 35px !important;
  padding-top: 5rem !important;
  gap: 2.69rem;
}
.adminListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100% !important;
  margin-top: 35px !important;
  padding-top: 5rem !important;
  gap: 2.69rem;
}
.listContainerResponsive {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  height: 100% !important;
  margin-top: 35px !important;
}

.listItemResponsive {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center;
}
.listItemIconResponsive {
  min-width: 0 !important;
}

.userProfileAvatar {
  width: 38px;
  height: 38px;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.userProfile span {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--color-white);
  font-family: Montserrat-Regular, sans-serif;
}

.logout {
  margin-left: 70px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}

.logout:hover {
  color: #a9ba37;
}

.timeChat {
  color: #a9ba37;
}

.header {
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

.icon {
  cursor: pointer;
  width: 25px;
  margin-top: -40px;
}

.caremeIcon {
  width: 138px;
  height: 40px;
  margin: 0 979px 0 58px;
  object-fit: contain;
}

.iconInactive {
  object-fit: contain;
  width: 49px;
  filter: brightness(0) saturate(100%) invert(98%) sepia(34%) saturate(269%) hue-rotate(261deg)
    brightness(115%) contrast(95%);
}

.iconInactiveResponsive {
  object-fit: contain;
  width: 20px;
  filter: brightness(0) saturate(100%) invert(98%) sepia(34%) saturate(269%) hue-rotate(261deg)
    brightness(115%) contrast(95%);
}

.iconActive {
  object-fit: contain;
  width: 49px;
  filter: invert(39%) sepia(47%) saturate(374%) hue-rotate(117deg) brightness(95%) contrast(94%);
}

.iconActiveResponsive {
  object-fit: contain;
  width: 20px;
  filter: invert(39%) sepia(47%) saturate(374%) hue-rotate(117deg) brightness(95%) contrast(94%);
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66px;
  height: 48px;
  padding: 12px 22px 11px !important;
  margin: 0 0 16px !important;
  border-radius: 12px !important;
}

.iconContainerActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66px;
  height: 48px;
  border-radius: 12px !important;
  padding: 12px 22px 11px !important;
  margin: 0 0 16px !important;
  background-color: var(--verde-topbar-y-botones) !important;
}

.iconTraining {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66px;
  height: 48px;
  border-radius: 12px !important;
  padding: 12px 22px 11px !important;
  margin: 0 0 16px !important;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.iconMenu {
  height: 34px;
  margin-left: 35px;
  object-fit: contain;
  cursor: pointer;
}

.iconMenu:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.title {
  margin-left: 110px !important;
  font-family: Montserrat-SemiBold, serif !important;
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 59px !important;
  letter-spacing: -0.004em !important;
  color: #f9f9f9 !important;
  text-transform: uppercase !important;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconButton:hover {
  background-color: inherit !important;
}

@media (max-width: 599px) {
  .caremeIcon {
    width: 130px;
    height: 40px;
    margin: 0 979px 0 30px;
    object-fit: contain;
  }

  .iconContainer {
    padding: 15px !important;
  }
  .iconContainerActive {
    padding: 15px !important;
  }
}

@media (max-width: 500px) {
  .caremeIcon {
    width: 110px;
    height: 40px;
  }

  .navRightContainer button {
    padding: 8px !important;
    margin: 0 !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
