.headerContainer {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: #474747 !important;
}

.buttonContainer {
  width: fit-content;
  column-gap: 1rem;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: flex-end;
  margin-left: 20px;
}

.buttonSubmit {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones) !important;
}

.buttonCancel {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  border: 2px solid var(--verde-topbar-y-botones) !important;
  text-transform: uppercase !important;
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  color: var(--verde-topbar-y-botones) !important;
}

.userName {
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
  margin-bottom: 15px !important;
  overflow-wrap: break-word !important;
}

.roleText {
  font-family: Montserrat-Extrabold, sans-serif !important;
  font-size: 36px !important;
  font-weight: 100 !important;
  line-height: 44px !important;
  letter-spacing: -0.004em !important;
  text-align: center !important;
}

.inputTitle {
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  margin: 5px !important;
  color: #2e7161 !important;
}

.typoText {
  min-height: 40px;
  max-height: 80px;
  color: #474747;
  font-family: Montserrat-Regular, sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  max-width: inherit !important;
}

.textFieldBox {
  display: flex !important;
  width: auto;
  font-family: 'Montserrat-Semibold', sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: -0.004em;
  text-align: left;
}

.textField {
  width: 100%;
  margin-right: 20px !important;
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
}

.form {
  width: 100%;
  display: flex;
  padding: 2rem 1rem 1rem 1rem;
  justify-content: center;
  min-height: 600px;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  height: 200px;
  text-align: center;
  border-radius: 4px;
  width: 22%;
}

.customIndicator {
  background-color: #2e7161 !important;
}

.customRootTabs {
  border-top-left-radius: 27px;
}
.iconEdit {
  margin-right: 20px !important;
  margin-left: 10px !important;
  cursor: pointer;
  width: 25px;
  height: 25px;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale(1.8);
    transition: all 0.2s ease-in-out;
    color: black;
  }
}
.tab {
  display: inline-block;
  padding: 10px;
  color: #474747;
  text-decoration: none;
  transition: color 0.3s;
  background: #f9f9f9 !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
  text-transform: capitalize !important;
}

.tabActive {
  padding: 10px;
  background: #f9f9f9 !important;
  color: #2e7161 !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
  text-transform: capitalize !important;
}

.firstTab {
  border-top-left-radius: 10px; /* Redondear borde superior izquierdo */
}

.lastTab {
  border-top-right-radius: 27px !important; /* Redondear borde superior derecho */
}

@media (max-width: 1000px) {
  .textFieldBox {
    flex-direction: column;
    width: auto;
    max-width: 500px;
  }

  .textField {
    width: auto;
    margin-bottom: 20px !important;
  }

  .select {
    width: auto;
  }
}
