.header {
  background-color: #f9f9f9;
  border-radius: 50%;
  box-shadow: inset 0px 0px 80px 0px #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  width: 200px;
  height: 200px;
}

.headerLogin {
  height: 150px;
  background-color: #2e7161;
  box-shadow: inset 0px 0px 80px 0px #2e7161;
}
.navigator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 100%;
  background-color: #2e7161;
  padding-top: 40px;
}

.navigatorLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #2e7161;
  margin-bottom: 20px;
}

.link {
  color: #fff;
  font-family: Montserrat-SemiBold, sans-serif !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: -0.004em !important;
  text-transform: uppercase !important;
  margin-right: 10px !important;
}

.linkLogin {
  color: #fff;
  font-family: Montserrat-SemiBold, sans-serif !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: -0.004em !important;
}

.link:hover {
  color: #e8e7e7;
  transition: 0.4s;
}

.logo {
  object-fit: contain;
  width: 140px;
}

.text {
  color: #fff;
}
