.pageContainer {
  padding-top: 200px;
  width: 100%;
  padding-bottom: 20px;
}

.label {
  font-weight: bold;
}

.containerForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 100%;
  margin-right: 14px;
  border: 1px dotted red;
}
.typoText {
  width: 350px;
  height: 40px;
  color: #474747;
  margin-right: 20px !important;
  font-family: Montserrat-Regular, sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
}
.headerContainer {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
  display: flex;
  justify-content: space-between;
}

.headerNewFamiliar {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.userName {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
}
.headerTitle {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: #474747 !important;
}

.autocomplete {
  width: 100%;
  border-radius: 32px !important;
}

.fields {
}

.form {
  width: 100%;
  display: flex;
  padding: 2rem 1rem 1rem 1rem;
  justify-content: center;
  min-height: 600px;
}
.modalNewFamiliarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 14vh;
  margin-left: 25vw;
  width: 50% !important;
  border-radius: 32px !important;
  background: #f9f9f9;
  box-shadow: 0px 3px 3px 0px #00000040;
  border: none !important;
  margin-bottom: 4px;
  padding: 25px;
}
.containerAddRelative {
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  width: 100% !important;
  border-radius: 0 27px 27px 27px !important;
  background: #f9f9f9;
  box-shadow: 0px 3px 3px 0px #00000040;
  border: none !important;
  margin-bottom: 4px;
  max-height: 600px;
  min-height: 600px;
}
.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  height: 200px;
  text-align: center;
  border-radius: 4px;
  width: 22%;
}
.roleText {
  font-family: Montserrat-Extrabold, sans-serif !important;
  font-size: 36px !important;
  font-weight: 100 !important;
  line-height: 44px !important;
  letter-spacing: -0.004em !important;
  text-align: center !important;
}
.textFieldBox {
  display: flex !important;
  width: auto;
  font-family: Montserrat-Semibold, sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: -0.004em;
  text-align: left;
}

.checkboxLabel {
  color: #474747;
  font-family: Montserrat-Semibold, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.005rem;
}

.inputTitle {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  margin: 5px !important;
  color: #2e7161 !important;
}

.label {
  width: 25%;
  font-weight: bold;
}

.dateFields {
  width: 10rem;
  margin-right: 220px !important;
}

.boxAddPatient > img {
  margin: 0 50px 0 0 !important;
  object-fit: contain !important;
}

.buttonContainer {
  column-gap: 1rem;
  justify-content: flex-end;
  margin-top: 15px;
  align-items: flex-end;
}

.buttons {
  margin-top: 6.5%;
  align-items: center;
  width: 100%;
  height: 2rem;
}
.textField {
  width: 350px;
  margin-right: 20px !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
}
.textFieldPass {
  width: 250px;
  margin-right: 20px !important;
  font-family: Montserrat-Semibold, sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: -0.004em;
  text-align: left;
}

.buttonCancel {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  border: 2px solid var(--verde-topbar-y-botones) !important;
  text-transform: UPPERCASE !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: var(--verde-topbar-y-botones) !important;
}
.buttonSubmit {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: UPPERCASE !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones) !important;
}

.buttonAdd {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: UPPERCASE !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones) !important;
}

.participantBox {
  margin-left: 1.5%;
  margin-top: 3%;
}

.buttonAddFamiliar {
  min-width: fit-content !important;
  padding-right: 3% !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  justify-content: left !important;
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones);
  white-space: nowrap !important;
}
.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  height: 200px;
  text-align: center;
  border-radius: 4px;
  width: 22%;
}

.userName {
  font-family: 'Montserrat-Semibold', sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
  margin-bottom: 15px !important;
  overflow-wrap: break-word !important;
}

.roleText {
  font-family: Montserrat-Extrabold, sans-serif !important;
  font-size: 36px !important;
  font-weight: 100 !important;
  line-height: 44px !important;
  letter-spacing: -0.004em !important;
  text-align: center !important;
}

.buttonRemoveFamiliar {
  min-width: fit-content !important;
  padding-right: 3% !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  justify-content: left !important;
  color: #f9f9f9 !important;
  background: var(--rojo-botones-eliminar);
  white-space: nowrap !important;
}
@media (max-width: 1280px) {
  .textFieldBox {
    flex-direction: column;
    width: auto;
    max-width: 500px;
  }

  .textField {
    width: auto;
    margin-bottom: 20px !important;
  }

  .select {
    width: auto;
  }
}

@media (max-width: 1200px) {
  .containerAddRelative {
    display: flex;
    flex-direction: column;
    justify-content: safe center;
    align-items: center;
    width: 100% !important;
    border-radius: 27px !important;
    background: #f9f9f9;
    box-shadow: 0px 3px 3px 0px #00000040;
    border: none !important;
    margin-bottom: 4px;
    max-height: 1200px;
    min-height: 600px;
  }
}
