.buttonContainer {
  width: 100%;
  column-gap: 1rem;
  justify-content: center;
  margin-top: 5px;
  align-items: flex-end;
  margin-bottom: 24px;
}

.buttonAdd {
  min-width: fit-content !important;
  padding-right: 3% !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  justify-content: left !important;
  color: #474747 !important;
  border: 1px solid var(--verde-topbar-y-botones) !important;
  white-space: nowrap !important;
}

.buttonRemove {
  min-width: fit-content !important;
  padding-right: 3% !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  justify-content: left !important;
  color: #474747 !important;
  border: 1px solid #47474794 !important;
  white-space: nowrap !important;
}

.buttonSave {
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones) !important;
  border-radius: 32px !important;
  font-family: Montserrat-Semibold, sans-serif !important;
}

.buttonCancel {
  border: 1px solid var(--verde-topbar-y-botones) !important;
  color: var(--verde-topbar-y-botones) !important;
  background: #f9f9f9 !important;
  border-radius: 32px !important;
  font-family: Montserrat-Semibold, sans-serif !important;
}

.textTrainingList {
  font-family: Montserrat-Semibold, sans-serif !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
  padding-top: 3%;
}
