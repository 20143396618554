.pageContainer {
  padding-top: 200px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  width: 100% !important;
  border-radius: 27px;
  background: #f9f9f9;
  box-shadow: 0 3px 3px 0 #00000040;
  border: none !important;
  margin-bottom: 4px;
  max-height: 600px;
  min-height: 600px;
}

.headerContainer {
  background: #ffffff;
  margin: 0 0 25px;
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 0 32px 32px 32px;
  padding: 0;
  width: fit-content;

  p {
    font-family: Montserrat-Regular, sans-serif !important;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 39px !important;
    letter-spacing: -0.004em !important;
    text-align: left !important;
    padding: 15px;
  }
}

.captionContainer {
  background: #ffffff;
  margin: 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 0;
}

.roleContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.roleContainer img {
  margin-right: 15px;
}

@media (max-width: 1500px) {
  .roleContainer {
    width: 80%;
    flex-wrap: wrap;
    justify-content: left;
  }

  .role {
    margin: 10px 30px 10px 0;
  }
}

@media (max-width: 600px) {
  .pageContainer {
    margin: 10px;
    min-height: calc(100vh - 9rem);
  }

  .roleContainer {
    margin: 10px;
  }

  .captionContainer {
    margin: 10px;
  }
}

.formControl {
  padding-bottom: 5px;
}
