@font-face {
  font-family: 'Poppins';
  src: url('./assets/typographies/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/typographies/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./assets/typographies/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/typographies/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
}

:root {
  --color-dark-blue: #0063a6;
  --color-light-blue: #68b3e0;
  --color-orange: #f37e31;
  --color-gray: #515151;
  --color-gray-light: #fafafa;
  --color-white: #fff;
  --color-black: #000;
  --color-messages: #eae9ee;
  --color-selected-table-item: #d1d1d1;
  --color-tooltip-background: #616161;
  --color-dark-blue-RGB: 0, 99, 166;
  --color-light-blue-RGB: 104, 179, 224;
  --color-orange-RGB: 243, 126, 49;
  --color-gray-RGB: 81, 81, 81;
  --color-gray-light-RGB: 250, 250, 250;
  --color-white-RGB: 255, 255, 255;
  --color-black-RGB: 0, 0, 0;
  --color-messages-RGB: 234, 233, 238;
  --color-selected-table-item-RGB: 209, 209, 209;
  --color-tooltip-background-RGB: 97, 97, 97;
  --verde-topbar-y-botones: #2e7161;
  --gris-fondo-menu-y-textos: #474747;
  --rojo-botones-eliminar: #ec6d62;
}

h1,
h2 {
  color: var(--color-dark-blue);
}

h3,
h4,
h5,
h6 {
  color: var(--color-gray);
}

p {
  color: var(--color-gray);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-gray);
  background-color: var(--color-gray-light);
  font-family: 'Montserrat-Regular', sans-serif !important;
}

code {
  font-family: 'Montserrat-Regular', sans-serif !important;
}

main {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

/*.dropdown-menu.show {
  display: none !important;
}*/
