.headerContainer {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: #474747 !important;
}

.form {
  width: 100%;
  overflow: auto;
  padding: 28px 48px;
}

.buttonContainer {
  column-gap: 1rem;
  justify-content: flex-start;
  margin-top: 5px;
}

.buttonAddFamiliar {
  width: fit-content !important;
  padding-right: 3% !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  justify-content: left !important;
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones) !important;
}

.buttonRemoveFamiliar {
  width: fit-content !important;
  padding-right: 3% !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: none !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  justify-content: left !important;
  color: #f9f9f9 !important;
  background: var(--rojo-botones-eliminar) !important;
}

.buttonSubmit {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  color: #f9f9f9 !important;
  background: var(--verde-topbar-y-botones) !important;
}

.buttonCancel {
  width: 95px !important;
  height: 30px !important;
  border-radius: 32px !important;
  border: 2px solid var(--verde-topbar-y-botones) !important;
  text-transform: uppercase !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: -0.004em !important;
  color: var(--verde-topbar-y-botones) !important;
}

.userName {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  color: #474747 !important;
}

.roleText {
  font-family: Montserrat-Extrabold, sans-serif !important;
  font-size: 36px !important;
  font-weight: 100 !important;
  line-height: 44px !important;
  letter-spacing: -0.004em !important;
  text-align: center !important;
}

.inputTitle {
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  margin: 5px !important;
  color: #a9ba37 !important;
}

.typoText {
  width: 350px;
  height: 40px;
  color: #474747;
  margin-right: 20px !important;
  font-family: Montserrat-Regular, sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
}

.label {
  font-weight: bold;
}

.textFieldBox {
  display: flex !important;
  width: auto;
  font-family: Montserrat-Semibold, sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: -0.004em;
  text-align: left;
  margin-bottom: 0 !important;
}

.textField {
  width: 350px;
  margin-right: 20px !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600 !important;
  line-height: 41px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
}

.textFieldPass {
  width: 250px;
  margin-right: 20px !important;
  font-family: Montserrat-Semibold, sans-serif !important;
  font-size: 34px !important;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: -0.004em;
  text-align: left;
}

.form {
  height: 100%;
  padding: 20px;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 3rem 3rem 0;
  height: 200px;
  text-align: center;
  border-radius: 4px;
  width: 20%;
}

.roleIcon {
  padding-right: 10px;
  width: 25px;
}

.select {
  width: 400px;
  height: 40px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1280px) {
  .textFieldBox {
    flex-direction: column;
    width: auto;
    max-width: 500px;
  }

  .textField {
    width: auto;
    margin-bottom: 20px !important;
  }

  .textFieldPass {
    width: auto;
    margin-bottom: 20px !important;
  }

  .select {
    width: auto;
  }
}
