.newTreatmentButton {
  background-color: #2e7161 !important;
  color: #ffffff !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
  height: 60px !important;
  border-radius: 32px !important;
  border: 2px solid #2e7161 !important;
  display: flex !important;
  justify-content: space-around !important;
  width: 195px;
}

.newTreatmentButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registeredMedicine {
  font-family: Montserrat-Regular, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.004em;
  text-align: center;
  text-transform: uppercase;
  color: #474747;
}
.countMedicine {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 78.02px;
  letter-spacing: -0.004em;
  text-align: left;
  color: #2e7161;
}

.newTreatmentButtonText {
  display: flex;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.004em;
  text-align: left;
  color: #f9f9f9;
  margin-bottom: 4px;
  margin-left: 20px;
}

.boxTraining {
  border: 2px solid #2e7161;
  display: flex;
  border-radius: 20px;
  box-shadow: #000000;
  flex-direction: column;
  padding: 10px;
}
